import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    section as sectionClass,
    alternate,
    grid,
    titleText,
    subtitleText,
    contentText,
    image,
    imageRatio,
    withImage,
    noTitle,
} from './article-preview.module.scss';

import { ISection } from '../../models/section.model';

import Section from '../hoc/section';

interface IArticleProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

const ArticlePreview: React.FC<IArticleProps> = ({ className = '', section, TitleTag = 'h2' }) => {
    const {
        sectionId,
        content: { texts, media },
        css,
        style,
        settings,
    } = section;
    const alternateClass =
        settings?.repeatIndex && settings?.repeatIndex % 2 === 0 ? alternate : '';
    const [title, subtitle, description] = texts;

    const hasMedia = media.length > 0;

    return (
        <Section
            sectionId={sectionId}
            className={[
                sectionClass,
                alternateClass,
                hasMedia ? withImage : '',
                title ? '' : noTitle,
                className,
            ].join(' ')}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: contentText,
            }}
            css={css}
            style={style}
            title={title}
            subtitle={subtitle}
            description={description}
            TitleTag={TitleTag}
        >
            {hasMedia && <Image className={image} ratioClass={imageRatio} media={media} />}
        </Section>
    );
};

export default ArticlePreview;
